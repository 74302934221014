<template>
  <v-app>
    <v-layout row align-center justify-center ma-0>
      <v-flex xs12 sm12 md6 lg7 xl7 align-center justify-center text-center>
      </v-flex>
      <v-flex xs12 sm12 md6 lg5 xl5 align-center justify-center>
        <v-layout
          row
          warp
          align-center
          justify-center
          class="height: calc(100vh - 48px) !important;"
        >
          <div>
            <div>
              <v-img max-width="44" src="@/assets/Ganesh-Leaf-Logo.jpg"></v-img>
            </div>
            <div class="mb-8 mt-2">
              <span class="latterspace font-weight-bold">
                Welcome to <br />
                Petition Dashboard
              </span>
            </div>
            <div class="mt-4">
              <v-text-field
                width="360px"
                height="48"
                label="Username"
                :rules="[rules.required]"
                outlined
                dense
                block
              ></v-text-field>
            </div>
            <div class="text-right">
              <span class="forgotcolor"> Forgot Password </span>
            </div>
            <div class="mt-2">
              <v-text-field
                width="360"
                height="48"
                label="Password"
                outlined
                block
                dense
                :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show4 ? 'text' : 'password'"
                @click:append="show4 = !show4"
              ></v-text-field>
            </div>
            <v-btn
              depressed
              class="mt-5 text-none"
              block
              height="48"
              color="black"
              dark
              @click="signin()"
              >Sign in
            </v-btn>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>

    <div class="w-100 text-right pa-3">
      <span> Powered by Stoneage Solutions Pvt Ltd. All rights reserved. </span>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      show2: true,
      show3: false,
      show4: false,
      password: "Password",
      rules: {
        required: (value) => !!value || "Fill this Field",
      },
    };
  },
  methods: {
    signin() {
      this.$router.push("/dashboard");
    },
  },
};
</script> 

<style scoped>
.v-text-field {
  width: 360px;
}
::v-deep.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px !important;
  margin-bottom: 0px !important;
}
.forgotcolor {
  color: #1971c2;
  font-size: 14px !important;
}

::v-deep .v-btn {
  letter-spacing: 0px !important;
  font-size: 16px !important;
}
::v-deep .v-text-field__slot {
  margin-top: 3px;
}
::v-deep .v-input__append-inner {
  margin: auto 0px !important;
}
.latterspace {
  letter-spacing: 0.4px;
  color: #000000;
}
</style>